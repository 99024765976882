html,
body {
    height: 100%;
    margin: 0;
}

.header {
    height: 50px;
    background: #333;
}

.sidebar-wrap {
    width: 80px;
    height: calc(100% - 50px);
    overflow-x: visible;
    overflow-y: visible;
    position: fixed;
    top: 70px;
    left: 0;
    background: #005da8;
}

.sidebar-wrap .nav-item {
    padding: 20px 0;
    text-align: center;
}

.sidebar-wrap .nav-item .icon-wrap {
    background: rgba(255, 255, 255, 0.31);
    border-radius: 10px;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-wrap .nav-item .icon-wrap:hover {
    opacity: 0.6;
}

.sidebar-wrap .nav-item .icon-wrap img {
    width: 30px;
}

.sidebar-wrap .nav-item p {
    color: white;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .sidebar-wrap {
        width: 100%;
        height: calc(100% - 50px);
    }

    .sidebar-wrap .nav-item {
        padding: 14px 0;
    }

    .sidebar-wrap .nav-item .icon-wrap {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 1600px) {
    .sidebar-wrap .nav-item .dropdown button {
        font-size: 18px;
    }

    .sidebar-wrap .dropdown-item,
    .sidebar-wrap .nav-item .dropdown .dropdown button {
        font-size: 17px;
        padding: 8px 10px;
    }

    .sidebar-wrap .nav-item .dropdown-menu p {
        font-size: 18px;
        padding: 20px 0 5px;
    }

    .sidebar-wrap .nav-item .icon-wrap {
        width: 60px;
        height: 60px;
    }

    .sidebar-wrap .nav-item {
        /* width: 80px; */
        padding: 20px 0;
    }

    /* .sidebar-wrap .dropdown-menu {
           left: 70px !important;
       } */
}

@media (max-width: 1400px) {
    .sidebar-wrap .nav-item .dropdown button {
        font-size: 16px;
    }

    .sidebar-wrap .dropdown-item,
    .sidebar-wrap .nav-item .dropdown .dropdown button {
        font-size: 15px;
        padding: 6px 12px;
    }

    .sidebar-wrap .nav-item .dropdown-menu p {
        font-size: 16px;
        padding-top: 15px;
    }

    .sidebar-wrap .dropdown-menu {
        left: 64px !important;
    }

    .sidebar-wrap .nav-item .icon-wrap {
        width: 50px;
        height: 50px;
    }

    .sidebar-wrap .nav-item {
        /* width: 70px;  */
        padding: 20px 0;
    }

    .sidebar-wrap {
        width: 100px;
        overflow-x: inherit;
        overflow-y: inherit;
    }

    .sidebar-wrap .dropdown-menu {
        box-shadow: 5px 1px 6px 1px rgb(0 0 0 / 12%);
    }

    .sidebar-wrap .dropdown-menu .dropdown-menu {
        box-shadow: none;
    }
}

.sidebar-inner {
    height: calc(100vh - 76px);
    display: flex;
    /* justify-content: space-between; */
    background: linear-gradient(90deg, rgba(0, 93, 168, 1) 100px, rgba(255, 255, 255, 1) 80px);
    flex-wrap: nowrap !important;
}








@media (max-width: 991px) {
    .sidebar-wrap .dropdown-menu {
        top: 65px !important;
        left: 0 !important;
    }
}

@media (max-width: 768px) {
    .sidebar-wrap .dropdown-menu {}

    .sidebar-wrap {
        width: 100%;
        height: 80px;
        overflow: visible;
    }

    .sidebar-inner {
        height: 80px;
        background: #005da8;
    }

    .sidebar-wrap .nav-item {
        height: 80px;
        padding: 14px 0;
    }

    .logout {
        display: flex !important;
        align-items: center;
        justify-content: center;
        background: #4f8fc3;
        border-radius: 8px;
    }
}

@media (min-width: 767px) {
    .sidebar-wrap .submenu.dropdown-menu.show {
        top: 0 !important;
        left: 64px !important;
    }
}

@media (max-width: 768px) {
    .sidebar-inner.flex-md-column {
        flex-direction: row !important;
    }
}

@media (min-width: 768px) {
    .flex-md-column {
        flex-direction: column !important;
        min-height: 570px;
    }
}