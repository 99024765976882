.box-wrap {
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    height: 100%;
}

.patient-wrap table tbody tr:hover {
    background-color: #e9f5ff;
    cursor: pointer;
}

.dash-wrap {
    margin: 20px 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.box-wrap:hover {
    filter: brightness(97%);
}

.box-wrap p {
    width: 100%;
}

.box-wrap .texts-wrap {
    font-weight: 500;
    font-size: 22px;
}

.box-wrap .texts-wrap span {
    font-weight: 600;
    font-size: 22px;
    color: #005da8;
    padding-left: 5px;
}

.box-wrap .top-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-wrap p {
    font-weight: 400;
    font-size: 14px;
    color: #8e8e8e;
    margin: 0;
}

.four-cards {
    margin: 10px 0 20px;
}

.four-cards .custom-btn {
    padding: 12px 12px;
}

.box-wrap p span {
    font-size: 16px;
}

.box-wrap p img {
    padding: 0 10px 0 5px;
}

.view-all {
    color: #005da8;
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;
}

.box-wrap .icon-wrap img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
}

.dashSearch input,
.dashSearch select,
.dashSearch textarea {
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    background-color: transparent;
    margin-left: 0;
    height: 60px;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    padding: 5px 15px;
    resize: none;
}

.dashSearch .field-wrap {
    margin-bottom: 30px;
}

.dashSearch .field-wrap label {
    font-weight: 400;
    font-size: 18px;
}

.dashSearch .field-wrap input:focus,
.dashSearch .field-wrap select:focus,
.dashSearch .field-wrap textarea:focus {
    outline: 0;
}


/* responsive start */
@media (max-width: 1600px) {
    .box-wrap .texts-wrap {
        font-size: 20px;
    }

    .box-wrap .texts-wrap span {
        font-size: 20px;
    }

    /*.box-wrap .icon-wrap img {
     max-width: 70px;
    }*/

    /*.col:first-child .box-wrap .icon-wrap img {
     max-width: 85px;
    }*/

    .box-wrap {
        padding: 20px;
    }

    .view-all {
        font-size: 17px;
    }
}

@media (max-width: 1400px) {
    .box-wrap .texts-wrap {
        font-size: 16px;
    }

    .box-wrap .texts-wrap span {
        font-size: 16px;
    }

    .box-wrap p {
        font-size: 12px;
    }

    .box-wrap p span {
        font-size: 14px;
    }

    .box-wrap p img {
        padding: 0 6px 0 2px;
        max-width: 24px;
    }

    /*.box-wrap .icon-wrap img {
     max-width: 60px;
    }*/

    /*.col:first-child .box-wrap .icon-wrap img {
     max-width: 75px;
    }*/

    .box-wrap .top-wrap {
        margin-bottom: 8px;
    }

    .box-wrap {
        padding: 15px;
    }

    .four-cards {
        margin: 20px 0 10px;
    }

    .view-all {
        font-size: 15px;
    }
}

@media (max-width: 1200px) {}

@media (max-width: 991px) {}

@media (max-width: 767px) {
    .heading-wrap {
        display: flex;
    }

    .heading-wrap h1 {
        width: auto;
    }
}

@media (max-width: 480px) {
    .four-cards .row .col {
        width: 100%;
        flex: none;
    }
}