.slotStyle {
    word-wrap: break-word;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    color: #214469;
    display: flex;
    -webkit-filter: drop-shadow(0 5px 10px rgba(44, 121, 202, 0.05));
    filter: drop-shadow(0 5px 10px rgba(44, 121, 202, 0.05));
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    margin-right: 10px;
    padding: 6px 10px;
    text-align: center;
    transition: 0.2s;
    cursor: pointer;
    justify-content: center;
    width: fit-content;
}

.slotStyle:hover {
    background-color: #6c757d38 !important;
}

.requiredField {
    color: #ec1422;
}

.header-text {
    font-weight: 100;
    font-size: 25px;
    color: #0064a6;
}

.label-grey {
    color: #7b7c7d;
}

.col-section {
    margin-top: 15px;
}

.dashboard .heading-wrap h1 {
    font-size: 22px;
}

.dash-buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.dash-buttons .btn-group button {
    font-weight: 500;
    font-size: 18px;
    color: #696969;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    margin: 3px;
    margin-right: 8px;
    padding: 9px 5px;
}

.dash-buttons .btn-group {
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
}

.dash-buttons .btn-group button:last-child {
    margin-right: 3px;
}

.dash-buttons .btn-group button.active,
.dash-buttons .btn-group button:hover {
    background: #e9f5ff;
    color: #005da8;
}