.home-container-v {
    display: flex;
}

.cards-container-v {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    /* Adjust the width of your sidebar */
}

.card-v {
    width: calc(20% - 20px);
    margin: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    opacity: 0.8;
    border-radius: 10px;
    transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

}

.card-v:hover {
    opacity: 1;
    border: 2px solid #0066cc;
    box-shadow: 0 0 10px rgba(0, 102, 204, 0.5);
}

.card-v.hidden {
    display: none;
}

.card-v img {
    max-width: 100px;
    max-height: 100px;
    margin-top: 13px;
    margin-bottom: 13px
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}





.modal-body {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
}

.icon-row {
    display: flex;
    justify-content: space-around;
}

.icon-container {
    text-align: center;
}

.icon-box {
    cursor: pointer;
}

.modal-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.icon-text {
    margin-top: 5px;
}

