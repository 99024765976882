.chat-wrapper {
    display: flex;
    display: -ms-flexbox;
    margin-top: 30px;
}

.msg-sidebar {
    width: 440px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    margin-right: 20px;
}

.chat-wrapper .right-wrap {
    width: calc(100% - 460px);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}

.msg-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    margin-top: -20px;
    height: 700px;
    overflow: auto;
}

.msg-sidebar ul li {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 20px 0;
    cursor: pointer;
}

.common-wrap {
    display: flex;
}

.msg-sidebar .search-wrap {
    padding: 20px 20px 20px 15px;
}

.msg-sidebar .search-wrap input {
    width: 100%;
    border: 1px solid #EFEFEF;
    border-radius: 25px;
    margin-bottom: 10px;
}

.chat-wrapper .img-wrap {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    margin-right: 20px;
}

.chat-wrapper .img-wrap img {
    width: 100%;
}

.chat-wrapper .text-wrap h4 {
    font-weight: 500;
    font-size: 16px;
}

.chat-wrapper .text-wrap p,
.time-count-wrap {
    font-weight: 400;
    font-size: 14px;
    color: #6A6F7B;
    line-height: 1.3;
    margin: 0;
}

.time-count-wrap {
    min-width: 50px;
}

.msg-sidebar ul li:last-child {
    border-bottom: 0;
}

.detail-chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.opened-person {
    display: flex;
    align-items: center;
}

.msg-common {
    display: flex;
    align-items: center;
    width: 65%;
}

.msg-common.sender {
    margin-left: auto;
}

.msg-footer {
    margin: 20px;
}

.msg-footer .search-wrap {
    display: flex;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    align-items: center;
}

.msg-footer .search-wrap button {
    width: 100px;
    background: #15274B;
    border: 1px solid #15274B;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}

.msg-footer .search-wrap input {
    width: 100%;
    background-color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    color: #6A6F7B;
}

.body-wrap {
    padding: 0 20px;
    height: 585px;
    overflow: auto;
}

.chat-wrapper .right-wrap .img-wrap {
    width: 50px;
    height: 50px;
    min-width: 50px;
}

.detail-chat-header .date-time {
    font-weight: 400;
    font-size: 14px;
    color: #6A6F7B;
}

.msg-footer .search-wrap button img {
    padding-left: 6px;
}

.msg-footer .search-wrap button:hover {
    background: #005DA8;
    border-color: #005DA8;
}

.msg-common .msg-text {
    background-color: #EFEFEF;
    padding: 26px 30px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
}

.msg-common.receiver .msg-text {
    background-color: #EFEFEF;
    border-radius: 47px 47px 47px 0px;
}

.chat-msg-detail .msg-common.sender .msg-text {
    background-color: #005DA8;
    border-radius: 47px 47px 0px 47px;
    color: #fff;
}

.msg-common .img-wrap {
    margin-right: 15px;
}

.msg-common .msg-time {
    font-weight: 400;
    font-size: 14px;
    color: #6A6F7B;
}

.msg-common.sender .msg-time {
    text-align: right;
}

.msg-common.sender .text-wrap {
    margin-right: 15px;
}


/* responsive start */
@media (max-width: 1600px) {
    .msg-sidebar {
        width: 380px;
    }

    .chat-wrapper .right-wrap {
        width: calc(100% - 380px);
    }

    .chat-wrapper {
        margin-top: 20px;
    }

    .chat-wrapper .img-wrap {
        margin-right: 15px;
    }

    .chat-wrapper .text-wrap h4 {
        margin-bottom: 5px;
    }
}

@media (max-width: 1400px) {
    .msg-sidebar {
        width: 340px;
        margin-right: 15px;
    }

    .chat-wrapper .img-wrap {
        width: 52px;
        min-width: 52px;
        height: 52px;
    }

    .chat-wrapper .text-wrap h4 {
        font-size: 15px;
    }

    .chat-wrapper .text-wrap p,
    .time-count-wrap {
        font-size: 13px;
    }

    .chat-wrapper .right-wrap {
        width: calc(100% - 340px);
    }

    .msg-sidebar ul li {
        padding: 12px 0;
    }

    .msg-sidebar .search-wrap {
        padding: 12px 12px 20px 10px;
    }

    .chat-wrapper {
        margin-top: 10px;
    }

    .chat-wrapper .right-wrap .img-wrap {
        width: 42px;
        height: 42px;
        min-width: 42px;
    }

    .msg-common .msg-text {
        padding: 20px 25px;
        line-height: 1.3;
    }

    .msg-common .msg-time {
        font-size: 12px;
    }

    .msg-footer .search-wrap input {
        font-size: 13px;
    }

    .msg-footer .search-wrap button {
        font-size: 14px;
    }

    .detail-chat-header .date-time {
        font-size: 12px;
    }

    .msg-footer {
        margin: 12px;
    }

    .body-wrap {
        height: 450px;
    }

    .msg-sidebar ul {
        height: 500px;
    }
}



@media (max-width: 1200px) {
    .msg-common {
        width: 80%;
    }

    .msg-sidebar {
        width: 280px;
    }

    .chat-wrapper .right-wrap {
        width: calc(100% - 280px);
    }

    .detail-chat-header {
        margin: 0 10px 10px;
    }

    .body-wrap {
        padding: 0 10px;
    }
}


@media (max-width: 991px) {}


@media (max-width: 767px) {
    .chat-wrapper {
        display: block;
    }

    .msg-sidebar {
        width: 100%;
        margin-bottom: 10px;
    }

    .chat-wrapper .right-wrap {
        width: 100%;
    }

}