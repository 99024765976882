.heading-wrap {
    width: 100%;
}

.settings-container,
.program-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    font-size: 20px;
}

.notifications,
.program-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.settings-box,
.program-box {
    border: 2px solid blue;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.program-item {
    border: 1px solid #e0e0e0;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.program-name {
    font-size: 20px;
    /* Adjust the font size as needed */
}

.program-added-at {
    font-size: 14px;
    /* Smaller font size for the added at text */
    color: #888;
    /* Adjust the color as needed */
}

.program-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.program-info {
    flex-grow: 1;
}

.program-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    font-size: 20px;
    width: 100%;
    /* Set the width to 100% to take up the entire available width */
}

.program-box {
    border: 2px solid blue;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    /* Set the width to 100% to take up the entire available width */
}

.no-data-message {
    color: #555;
    /* Adjust the color as needed */
    font-size: 16px;
    /* Adjust the font size as needed */
    text-align: center;
    /* Adjust the margin as needed */
    margin-bottom: 50px;
}

.settings-box,
.program-box {
    border: 2px solid blue;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    height: 350px;
    /* Set a fixed height as needed */
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    /* Push the button to the bottom of the container */
}