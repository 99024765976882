.heading-wrap .right-wrap {
    display: flex;
}

.call-chat-outer {
    display: flex;
    margin-top: 22px;
}

.heading-wrap h1 .big-head {
    display: block;
    padding-top: 15px;
    padding-left: 35px;
    font-weight: 600;
    font-size: 30px;
}

.call-screen-wrap {
    background: #000;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    height: 75vh;
}

.call-screen-wrap #participants-view {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    bottom: 0;
    display: flex !important;
    flex-direction: column;
    overflow: auto;
    width: 130px;
}

.call-screen-wrap #participants-view #participants-view-inner {

    margin-top: auto;
}

.call-screen-wrap #participants-view .participant-view {
    flex-shrink: 0;
    margin: 1px;
    aspect-ratio: 16/9;
    display: flex;
}

.call-screen-wrap #participants-view .participant-view canvas {
    width: 100%;
    border: 1px solid #5e5c5c;
    margin-bottom: 80px;
}


.call-screen-wrap .participant-avatar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-screen-wrap .participant-avatar div {
    background-color: #999999;
    width: 55px;
    height: 55px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    font-weight: 500;
    border-radius: 50%;
}

.call-chat-outer .chat-sidebar {
    width: 30%;
    margin-left: 20px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 20px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(33, 68, 105, 0.25);
}

.chat-header .left,
.chat-header .right {
    display: flex;
    align-items: center;
}

.chat-header .img-wrap {
    width: 53px;
    height: 53px;
    background: #D9D9D9;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 16px;
}

.chat-header .left h4 {
    font-weight: 600;
    font-size: 25px;
    color: #000000;
    margin: 0;
}

.chat-header .right a {
    padding: 0 5px;
    margin-left: 15px;
    display: inline-block;
}

.call-chat-outer .msg-footer .search-wrap {
    background: #F6F6F6;
    border-radius: 18px;
    overflow: hidden;
    padding: 8px 15px;
}

.call-chat-outer .msg-footer .search-wrap input {
    background-color: #F6F6F6;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: left center;
}

.call-chat-outer .msg-footer .search-wrap button {
    background: #2C79CA;
    border-radius: 13px;
    border: 0;
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-left: 15px;
}

.call-chat-outer .msg-footer .search-wrap button img {
    padding: 0;
}

.call-chat-outer .msg-footer .search-wrap a {
    margin-left: 15px;
}

.call-chat-outer .msg-common {
    display: block;
}

.call-chat-outer .chat-body {
    padding: 0 20px;
    height: 65vh;
    overflow: auto;
}

.msg-common.sender .msg-text {
    background-color: #EAF0F5;
    text-align: right;
}

.chat-body .msg-common .msg-text {
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #214469;
    padding: 14px 25px;
    position: relative;
}

.chat-body .msg-common .msg-text:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: 0;
    border-radius: 5px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 22px solid #F3F8FF;
}

.msg-common.receiver .msg-text:after {
    left: -8px;
}

.msg-common.sender .msg-text:after {
    left: auto;
    right: -10px;
    border-bottom: 22px solid #EAF0F5;
}

.msg-common.receiver .msg-text {
    background-color: #F3F8FF;
}

.chat-header .right a:hover {
    opacity: 0.6;
}

.call-chat-outer .msg-footer .search-wrap button:hover {
    background: #214469;
}

.main-video-wrap {
    /* background-image: url(../../assets/images/doctor-video-call-virtual-appointment.png); */
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
}

.call-screen-wrap .video-wrap {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 220px;
    height: 230px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: transparent;
    border-radius: 13px;
    margin: 0 25px 30px 0;
}

.call-screen-wrap .tool-wrap {
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
    width: 100%;
    text-align: center;
    z-index: 20;
    display:flex;
    flex-direction: row;
    justify-content: center;
}

/* .call-screen-wrap .tool-wrap span {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
    display: inline-block;
    margin-right: 20px;
    transition: .2s ease;
} */

.call-screen-wrap .tool-wrap .button{
    width: 50px;
    height: 50px;
    line-height: 25px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
    display: inline-block;
    margin-right: 15px;
    padding-bottom: 30px;
    transition: .2s ease;
}

.call-screen-wrap .tool-wrap .button:hover {
    background: #4d7bac;
}

.call-screen-wrap .tool-wrap .button:hover svg {
    filter: brightness(10) ;
}

.call-screen-wrap .tool-wrap .sub-button:hover svg {
    filter: brightness(10) ;
    /* color: #4d7bac; */
    stroke-width: 15%;
    stroke: white;
 }

.call-screen-wrap .tool-wrap .call-wrap {
    background: #D92029;
}

.camPicker:hover {
    background-color: #4d7bac !important;
    color: white;
}

.call-chat-outer .msg-footer .search-wrap a:hover img {
    opacity: 0.6;
}

/* audio diff style start */
.audio-screen .main-video-wrap {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.audio-screen .call-screen-wrap .video-wrap {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

/* audio style ends */

/* tab sidebar-style start */

.sidetab-outer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 0;
    height: 530px;
    overflow: auto;
}

.sidetab-outer ul li {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
}

.sidetab-outer {
    margin: 10px;
}

.sidetab-outer .nav-tabs {
    border: 1px solid #E9F5FF;
    border-radius: 10px;
    margin-bottom: 20px;
    height: auto;
}

.sidetab-outer .nav-tabs .nav-item button {
    font-weight: 500;
    font-size: 18px;
    color: #8E8E8E;
    border: 0;
    width: 100%;
    text-align: center;
    height: 50px;
}

.sidetab-outer .nav-tabs .nav-item {
    padding: 3px;
    margin: 0;
    border: 0;
    width: 50%;
}

.sidetab-outer .nav-tabs .nav-item button.active,
.sidetab-outer .nav-tabs .nav-item button:hover {
    background: #E9F5FF;
    border-radius: 7px;
    color: #005DA8;
}

.ctext-wrap p {
    font-weight: 500;
    font-size: 14px;
    color: #8E8E8E;
    margin: 0;
}

.rtext-wrap h2 {
    font-weight: 700;
    font-size: 26px;
    margin: 0;
    line-height: 1;
}

.ctext-wrap h4 {
    font-weight: 500;
    font-size: 18px;
}

.sidetab-outer .text-wrap .ctext-wrap {
    padding: 19px 10px;
    width: 70%;
}

.sidetab-outer .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    width: 80px;
}

.sidetab-outer .icon-wrap::after {
    content: '';
    position: absolute;
    background: rgba(0, 93, 168, 0.12);
    width: 100%;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    left: -28px;
}

.sidetab-outer .text-wrap {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.rtext-wrap h2.green-text {
    color: #00B050;
}

.rtext-wrap h2.yellow-text {
    color: #FFC000;
}

.rtext-wrap h2.red-text {
    color: #FF0000;
}

.rtext-wrap h2 span {
    font-weight: 500;
    font-size: 14px;
    color: #8E8E8E;
}

/* tab sidebar style ends */

/* add-note style start */
.add-note-wrap form {
    padding: 0 20px;
}

.add-note-wrap form textarea {
    border: 0;
    font-weight: 500;
    font-size: 16px;
    resize: none;
    color: #214469;
    height: 530px;
    width: 100%;
    margin-bottom: 20px;
}

.add-note-wrap form .btn-wrap .custom-btn {
    width: 160px;
}

.add-note-wrap form .btn-wrap .custom-btn:first-child {
    margin-right: 20px;
}

.add-note-wrap form .btn-wrap {
    text-align: center;
    margin-bottom: 30px;
}

.add-note-wrap form textarea:focus {
    outline: 0;
}

/* end note style */









/* responsive start */
@media (max-width: 1600px) {
    .call-chat-outer .chat-sidebar {
        width: 30%;
    }

    .chat-header .left h4 {
        font-size: 22px;
    }

    .chat-header .img-wrap {
        margin-right: 8px;
    }

    .chat-header {
        margin: 0 15px 15px;
        padding: 15px 0;
    }

    .chat-body .msg-common .msg-text {
        font-size: 15px;
    }

    .msg-common .msg-time {
        font-size: 13px;
    }

    .heading-wrap h1 .big-head {
        font-size: 25px;
        padding: 12px 0 0 25px;
    }

    .call-chat-outer .chat-body {
        height: 65vh;
    }

    .call-chat-outer {
        margin-top: 15px;
    }

    .call-screen-wrap .video-wrap {
        width: 190px;
        height: 190px;
        margin: 0 20px 30px 0;
    }

    .call-screen-wrap .tool-wrap span {
        width: 45px;
        height: 45px;
        line-height: 45px;
        margin-right: 15px;
    }

    .call-screen-wrap .tool-wrap {
        margin-bottom: 30px;
    }

    /* tab-block style */
    .sidetab-outer .nav-tabs .nav-item button {
        font-size: 16px;
    }

    .ctext-wrap h4 {
        font-size: 16px;
        margin-bottom: 6px;
    }

    .sidetab-outer .text-wrap {
        padding: 0 10px;
    }

    .rtext-wrap h2 {
        font-size: 22px;
    }

    /* end tab style */

    /* note style */

    .add-note-wrap form .btn-wrap .custom-btn {
        width: 140px;
    }

    .add-note-wrap form .btn-wrap .custom-btn:first-child {
        margin-right: 12px;
    }

    .add-note-wrap form textarea {
        height: 480px;
    }

    /* end note style */
}

@media (max-width: 1400px) {
    .call-chat-outer .chat-sidebar {
        width: 30%;
        margin-left: 15px;
    }

    .heading-wrap h1 .big-head {
        font-size: 22px;
        padding: 10px 0 0 20px;
    }

    .chat-body .msg-common .msg-text {
        padding: 10px 18px;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .msg-common .msg-time {
        font-size: 12px;
    }

    .chat-header .left h4 {
        font-size: 18px;
    }

    .chat-header .img-wrap {
        width: 40px;
        height: 40px;
    }

    .call-chat-outer .chat-body {
        height: 65vh;
    }

    .call-screen-wrap .tool-wrap span {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 12px;
    }

    .call-screen-wrap .tool-wrap img {
        width: 18px;
    }

    .call-screen-wrap .tool-wrap {
        margin-bottom: 20px;
    }

    .call-screen-wrap .video-wrap {
        width: 160px;
        height: 160px;
        margin: 0 18px 20px 0;
    }

    .chat-header .right a.dot-icon img {
        width: 4px;
    }

    .chat-header .right a img {
        width: 15px;
    }

    .chat-header .right a {
        margin-left: 10px;
    }

    .call-chat-outer .msg-footer .search-wrap a img {
        width: 20px;
    }

    .call-chat-outer .msg-footer .search-wrap a {
        margin-left: 10px;
    }

    .call-chat-outer .msg-footer .search-wrap input {
        background-size: 14px;
    }

    .call-chat-outer .msg-footer .search-wrap {
        padding: 5px 10px;
    }

    .call-chat-outer .msg-footer .search-wrap button {
        width: 34px;
        min-width: 34px;
        height: 34px;
    }

    /* tab style start */
    .sidetab-outer .nav-tabs .nav-item button {
        font-size: 15px;
        height: 40px;
        padding: 10px;
    }

    .sidetab-outer .text-wrap .ctext-wrap {
        padding: 12px 10px;
    }

    .sidetab-outer .icon-wrap::after {
        height: 95px;
        width: 95px;
    }

    .sidetab-outer .icon-wrap img {
        max-width: 28px;
    }

    .sidetab-outer .icon-wrap {
        width: 65px;
    }

    .sidetab-outer .text-wrap {
        width: calc(100% - 65px);
    }

    .rtext-wrap h2 {
        font-size: 20px;
    }

    .rtext-wrap h2 span {
        font-size: 12px;
    }

    .ctext-wrap p {
        font-size: 12px;
    }

    .ctext-wrap h4 {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .sidetab-outer ul li {
        margin-bottom: 10px;
    }

    /* tab style ends */
    /* note style */
    .add-note-wrap form textarea {
        height: 390px;
    }

    /* end note style */
}


@media (max-width: 1200px) {}


@media (max-width: 991px) {}


@media (max-width: 767px) {
    .call-screen-wrap {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .call-chat-outer {
        display: block;
    }

    .call-chat-outer .chat-sidebar {
        width: 100%;
        margin: 0;
    }
}

.my-view-block {
    z-index: 10;
    pointer-events: none;
}

.my-view-block .my-view-intial {
    width: 150px;
    height: 150px;
    background: #999;
    font-size: 45px;
    font-weight: 600;
}

.my-view-block .my-view-video {
    width: 100%;
}

.my-view-block .my-view-video>.inner {
    width: 100%;
}